import { useCallback, useEffect, useRef, useState } from 'react';
import { NextRouter, useRouter } from 'next/router';
import dateUtil, { dateToQuartile } from '../lib/dayjs';
import useSearchParams from './useSearchParams';
import { useDispatch, useSelector } from 'react-redux';
import {
  setBookingId as setBookingIdAction,
  setChangeBooking as setChangeBookingAction,
  setKids as setKidsAction,
  setDay as setDayAction,
  setEndQuartile as setEndQuartileAction,
  setHour as setHourAction,
  setMinute as setMinuteAction,
  setMonth as setMonthAction,
  setSeatings as setSeatingsAction,
  setStartQuartile as setStartQuartileAction,
  setYear as setYearAction,
  setZone as setZoneAction,
  setModal as setModalAction,
  setLang,
  setRequestedTime as setRequestedTimeAction,
  setSuggestedTime as setSuggestedTimeAction,
  setSelectedRelatedVenue as setSelectedRelatedVenueAction,
  setRelatedVenueVariant as setRelatedVenueVariantAction,
  setPlaceId as setPlaceIdAction,
  setOccupiedSlotSelected as setOccupiedSlotSelectedAction,
  setPrevWaitlistReservations as setPrevWaitlistReservationsAction,
  setWaitlistQuartiles as setWaitlistQuartilesAction,
} from '../services/paramsSlice';

export const paramTimeToDate = (hour: number | string, minute: number | string) => {
  const timestamp = dateUtil()
    .set('hour', typeof hour === 'string' ? parseInt(hour, 10) : hour)
    .set('minute', typeof minute === 'string' ? parseInt(minute, 10) : minute);
  return timestamp;
};

export const paramTimeToTimestamp = (hour: number | string, minute: number | string) => {
  const timestamp = paramTimeToDate(hour, minute);
  return timestamp.isValid() ? timestamp.format('HH:mm') : '-';
};

type Params = {
  seatings: string;
  kids: string;
  year: string;
  month: string;
  day: string;
  hour: string;
  minute: string;
  startQuartile: number;
  endQuartile: number;
  bookingId: string;
  changeBooking: string;
  zone: string;
  inTheCircus?: boolean;
  lang?: string;
  isApp: boolean;
  appVersion?: string;
  name: string;
  email: string;
  phone: string;
  ref: string;
};

interface ReturnTypes {
  year: string;
  month: string;
  day: string;
  hour: string;
  minute: string;
  seatings: number;
  kids: number;
  startQuartile: number;
  endQuartile: number;
  bookingId: string;
  changeBooking: string;
  zone: string;
  lang?: string;
  name: string;
  requestedTime: string | null;
  suggestedTime: string | null;
  selectedRelatedVenue: string | null;
  relatedVenueVariant: string | null;
  placeId: string;
  occupiedSlotSelected: boolean;
  prevWaitlistReservations: any[];
  waitlistQuartiles: number[];
  setYear: (year: string) => void;
  setMonth: (month: string) => void;
  setDay: (day: string) => void;
  setHour: (hour: string) => void;
  setMinute: (minute: string) => void;
  setSeatings: (seatings: number) => void;
  setKids: (kids: number) => void;
  setEndQuartile: (endQuartile: number) => void;
  setBookingId: (bookingId: string) => void;
  setChangeBooking: (changeBooking: string) => void;
  setZone: (zone: string) => void;
  setStartQuartile: (startQuartile: number) => void;
  setLang: (lang: string) => void;
  setRequestedTime: (requestedTime: string | null) => void;
  setSuggestedTime: (suggestedTime: string | null) => void;
  setSelectedRelatedVenue: (selectedRelatedVenue: string | null) => void;
  setRelatedVenueVariant: (relatedVenueVariant: string | null) => void;
  setPlaceId: (placeId: string) => void;
  setOccupiedSlotSelected: (occupiedSlotSelected: boolean) => void;
  setPrevWaitlistReservations: (prevWaitlistReservations: any[]) => void;
  setWaitlistQuartiles: (waitlistQuartiles: number[]) => void;
  params: Params;
}

export function updateParams(params, replace: NextRouter['replace']) {
  const urlObj = new URL(window.location.href);
  Object.keys(params).forEach((key) => {
    urlObj.searchParams.set(key, `${params[key]}`);
  });
  const updatedUrl = urlObj.toString();
  replace(updatedUrl, undefined, { scroll: false, shallow: true });
}

export function useWatchBookingParams() {
  const router = useRouter();
  const params = useSearchParams();

  const {
    year,
    month,
    day,
    hour,
    minute,
    seatings,
    kids,
    startQuartile,
    endQuartile,
    bookingId,
    changeBooking,
    zone,
    lang,
    setYear,
    setMonth,
    setDay,
    setHour,
    setMinute,
    setSeatings,
    setKids,
    setBookingId,
    setChangeBooking,
    setEndQuartile,
    setZone,
    setLang,
    setStartQuartile,
  } = useBookingParams();

  // useEffect(() => {
  //   updateParams(
  //     {
  //       year,
  //       month,
  //       day,
  //       hour,
  //       minute,
  //       startQuartile,
  //       endQuartile,
  //       bookingId,
  //       changeBooking,
  //       zone,
  //       seatings,
  //       kids,
  //     },
  //     router.replace,
  //   );
  // }, [
  //   year,
  //   month,
  //   day,
  //   hour,
  //   minute,
  //   seatings,
  //   kids,
  //   startQuartile,
  //   endQuartile,
  //   bookingId,
  //   changeBooking,
  //   zone,
  // ]);

  useEffect(() => {
    console.log('setting in redux', router.query);

    const searchParams = {
      year: params.get('year'),
      month: params.get('month'),
      day: params.get('day'),
      hour: params.get('hour'),
      minute: params.get('minute'),
      endQuartile: params.get('endQuartile'),
      bookingId: params.get('bookingId'),
      changeBooking: params.get('changeBooking'),
      zone: params.get('zone'),
      seatings: params.get('seatings'),
      kids: params.get('kids'),
    };

    const setters = {
      year: setYear,
      month: setMonth,
      day: setDay,
      hour: setHour,
      minute: setMinute,
      endQuartile: (value) => setEndQuartile(+value),
      bookingId: setBookingId,
      changeBooking: setChangeBooking,
      zone: setZone,
      seatings: (value) => setSeatings(+value),
      kids: (value) => setKids(+value),
    };

    Object.keys(searchParams).forEach((key) => {
      const value = searchParams[key];
      if (value !== null && setters[key]) {
        setters[key](value);
      }
    });
  }, [
    router.asPath,
    setBookingId,
    setChangeBooking,
    setKids,
    setDay,
    setEndQuartile,
    setHour,
    setMinute,
    setMonth,
    setSeatings,
    setYear,
    setZone,
  ]);

  // useEffect(() => {
  //   setStartQuartile(dateToQuartile(paramTimeToDate(hour, minute)))
  // },[hour, minute])
}

export default function useBookingParams(): ReturnTypes {
  const dispatch = useDispatch();

  const setYear = useCallback((year: string) => dispatch(setYearAction(year)), [dispatch]);
  const setMonth = useCallback((month: string) => dispatch(setMonthAction(month)), [dispatch]);
  const setDay = useCallback((day: string) => dispatch(setDayAction(day)), [dispatch]);
  const setHour = useCallback((hour: string) => dispatch(setHourAction(hour)), [dispatch]);
  const setMinute = useCallback((minute: string) => dispatch(setMinuteAction(minute)), [dispatch]);
  const setSeatings = useCallback(
    (seatings: number) => dispatch(setSeatingsAction(seatings)),
    [dispatch],
  );
  const setKids = useCallback((kids: number) => dispatch(setKidsAction(kids)), [dispatch]);
  const setEndQuartile = useCallback(
    (endQuartile: number) => dispatch(setEndQuartileAction(endQuartile)),
    [dispatch],
  );
  const setStartQuartile = useCallback(
    (startQuartile: number) => dispatch(setStartQuartileAction(startQuartile)),
    [dispatch],
  );
  const setBookingId = useCallback(
    (bookingId: string) => dispatch(setBookingIdAction(bookingId)),
    [dispatch],
  );
  const setChangeBooking = useCallback(
    (changeBooking: string) => dispatch(setChangeBookingAction(changeBooking)),
    [dispatch],
  );
  const setZone = useCallback((zone: string) => dispatch(setZoneAction(zone)), [dispatch]);
  const setRequestedTime = useCallback(
    (requestedTime: string | null) => dispatch(setRequestedTimeAction(requestedTime)),
    [dispatch],
  );
  const setSuggestedTime = useCallback(
    (suggestedTime: string | null) => dispatch(setSuggestedTimeAction(suggestedTime)),
    [dispatch],
  );
  const setSelectedRelatedVenue = useCallback(
    (selectedRelatedVenue: string | null) =>
      dispatch(setSelectedRelatedVenueAction(selectedRelatedVenue)),
    [dispatch],
  );
  const setRelatedVenueVariant = useCallback(
    (relatedVenueVariant: string | null) =>
      dispatch(setRelatedVenueVariantAction(relatedVenueVariant)),
    [dispatch],
  );
  const setPlaceId = useCallback(
    (placeId: string) => dispatch(setPlaceIdAction(placeId)),
    [dispatch],
  );
  const setOccupiedSlotSelected = useCallback(
    (occupiedSlotSelected: boolean) =>
      dispatch(setOccupiedSlotSelectedAction(occupiedSlotSelected)),
    [dispatch],
  );
  const setPrevWaitlistReservations = useCallback(
    (prevWaitlistReservations: any[]) =>
      dispatch(setPrevWaitlistReservationsAction(prevWaitlistReservations)),
    [dispatch],
  );
  const setWaitlistQuartiles = useCallback(
    (waitlistQuartiles: number[]) => dispatch(setWaitlistQuartilesAction(waitlistQuartiles)),
    [dispatch],
  );
  const seatings = useSelector((state: any) => state.params.seatings);
  const kids = useSelector((state: any) => state.params.kids);
  const year = useSelector((state: any) => state.params.year);
  const month = useSelector((state: any) => state.params.month);
  const day = useSelector((state: any) => state.params.day);
  const hour = useSelector((state: any) => state.params.hour);
  const minute = useSelector((state: any) => state.params.minute);
  const startQuartile = useSelector((state: any) => state.params.startQuartile);
  const endQuartile = useSelector((state: any) => state.params.endQuartile);
  const bookingId = useSelector((state: any) => state.params.bookingId);
  const changeBooking = useSelector((state: any) => state.params.changeBooking);
  const zone = useSelector((state: any) => state.params.zone);
  const lang = useSelector((state: any) => state.params.lang);
  const isApp = useSelector((state: any) => state.params.isApp);
  const appVersion = useSelector((state: any) => state.params.appVersion);
  const name = useSelector((state: any) => state.params.name);
  const phone = useSelector((state: any) => state.params.phone);
  const email = useSelector((state: any) => state.params.email);
  const inTheCircus = useSelector((state: any) => state.params.inTheCircus);
  const ref = useSelector((state: any) => state.params.ref);
  const requestedTime = useSelector((state: any) => state.params.requestedTime);
  const suggestedTime = useSelector((state: any) => state.params.suggestedTime);
  const selectedRelatedVenue = useSelector((state: any) => state.params.selectedRelatedVenue);
  const relatedVenueVariant = useSelector((state: any) => state.params.relatedVenueVariant);
  const placeId = useSelector((state: any) => state.params.placeId);
  const occupiedSlotSelected = useSelector((state: any) => state.params.occupiedSlotSelected);
  const prevWaitlistReservations = useSelector(
    (state: any) => state.params.prevWaitlistReservations,
  );
  const waitlistQuartiles = useSelector((state: any) => state.params.waitlistQuartiles);

  const params: Params = {
    seatings: seatings.toString(),
    kids: kids.toString(),
    year,
    month,
    day,
    hour,
    minute,
    startQuartile,
    endQuartile,
    bookingId,
    changeBooking,
    zone,
    lang,
    isApp,
    appVersion,
    name,
    phone,
    email,
    inTheCircus,
    ref,
  };

  const returnData: ReturnTypes = {
    year,
    month,
    day,
    hour,
    minute,
    seatings,
    kids,
    startQuartile,
    endQuartile,
    bookingId,
    changeBooking,
    zone,
    lang,
    name,
    requestedTime,
    suggestedTime,
    selectedRelatedVenue,
    relatedVenueVariant,
    placeId,
    occupiedSlotSelected,
    prevWaitlistReservations,
    waitlistQuartiles,
    setYear,
    setMonth,
    setDay,
    setHour,
    setMinute,
    setSeatings,
    setKids,
    setBookingId,
    setChangeBooking,
    setEndQuartile,
    setStartQuartile,
    setZone,
    setLang,
    setRequestedTime,
    setSuggestedTime,
    setSelectedRelatedVenue,
    setRelatedVenueVariant,
    setPlaceId,
    setOccupiedSlotSelected,
    setPrevWaitlistReservations,
    setWaitlistQuartiles,
    params,
  };

  return returnData;
}
